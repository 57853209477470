
































import { Component, Vue } from 'vue-property-decorator'
import { IntegrationKind } from '@tada-team/tdproto-ts'
import { integrationsStore, uiStore } from '@/store'

@Component({ name: 'IntegrationsTabNew' })
export default class Integrations extends Vue {
  get kinds (): IntegrationKind[] {
    return integrationsStore.state.kinds
  }

  createNew (kind: string) {
    uiStore.actions.showModal({
      instance: 'IntegrationManagement',
      payload: { kind, uid: null },
    })
  }
}
